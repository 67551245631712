//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    //wb
    async loadProducts(items) {
      const products = [];
      for (let i = 0; i < items.length; i++) {
        products.push(this.$store.dispatch("product/getProduct", {
          p_id: items[i].nm_id,
          mp: this.reportSettings.datasource,
          date: this.formatDate1,
          date2: this.formatDate2,
          fbs: 0
        }));
      }
      for (let i = 0; i < products.length; i++) {
        try {
          const data = await products[i];
          items[i].product = data.title;
          items[i].image = data.image;
        } catch (e) {
          console.log(e);
        }
      }
      return items;
    },
    async tableLoader({
      query
    }) {
      let data = await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbFinancialAnalysis", "connections/getOzFinancialAnalysis", {}, {});
      if (data.type !== "excel") {
        if (this.reportSettings.datasource == "wb") {
          data.items = await this.loadProducts(data.items);
        }
      } else {
        const filter = data.datas[0].sheetFilter;
        const header = data.datas[0].sheetHeader;
        data.datas[0].sheetFilter = filter.filter(item => item !== "product");
        data.datas[0].sheetHeader = header.filter(item => item !== "产品");
      }
      return data;
    }
  },
  computed: {
    columns() {
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "报告编号",
        show: true,
        name: "realizationreport_id",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "开始日期",
        show: true,
        name: "date_from",
        type: "date",
        width: 140,
        filter: "dateRange",
        noOverflow: true
      }, {
        title: "结束日期",
        show: true,
        name: "date_to",
        type: "date",
        width: 140,
        filter: "dateRange",
        noOverflow: true
      },
      // {
      //     title: "suppliercontract_code",
      //     show: true,
      //     name: "suppliercontract_code",
      //     type: "text",
      //     width: 200,
      //     filter: "text",
      //     noOverflow: true,
      // },
      // {
      //     title: "rrd_id",
      //     show: true,
      //     name: "rrd_id",
      //     type: "text",
      //     width: 140,
      //     filter: "text",
      //     noOverflow: true,
      // },
      // {
      //     title: "gi_id",
      //     show: true,
      //     name: "gi_id",
      //     type: "text",
      //     width: 140,
      //     filter: "text",
      //     noOverflow: true,
      // },
      {
        title: "SKU",
        show: true,
        name: "nm_id",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "产品",
        show: true,
        name: "product",
        type: "text",
        width: 260,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "物品",
        show: true,
        name: "subject_name",
        type: "text",
        width: 160,
        filter: "text",
        noOverflow: true
      }, {
        title: "品牌",
        show: true,
        name: "brand_name",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "产品识别代码",
        show: true,
        name: "sa_name",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "条码",
        show: true,
        name: "barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "尺寸",
        show: true,
        name: "ts_name",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      },
      // {
      //     title: "条码",
      //     show: true,
      //     name: "barcode",
      //     type: "text",
      //     width: 140,
      //     filter: "text",
      //     noOverflow: true,
      // },
      // {
      //     title: "文件类型",
      //     show: true,
      //     name: "doc_type_name",
      //     type: "text",
      //     width: 160,
      //     filter: "text",
      //     noOverflow: true,
      // },
      // {
      //     title: "数量",
      //     show: true,
      //     name: "quantity",
      //     type: "number",
      //     width: 140,
      //     filter: "numberRange",
      //     noOverflow: true,
      // },
      {
        title: "零售价",
        show: true,
        name: "retail_price",
        type: "money",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "零售价有折扣",
        show: true,
        name: "retail_price_withdisc_rub",
        type: "money",
        width: 250,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "实际售价",
        show: true,
        name: "retail_amount",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "约定%折扣 %",
        show: true,
        name: "sale_percent",
        type: "percent",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      },
      // {
      //     title: "佣金比例",
      //     show: true,
      //     name: "commission_percent",
      //     type: "percent",
      //     width: 200,
      //     filter: "numberRange",
      //     noOverflow: true,
      // },
      {
        title: "仓库",
        show: true,
        name: "office_name",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      },
      // {
      //     title: "付款理由",
      //     show: true,
      //     name: "supplier_oper_name",
      //     type: "text",
      //     width: 220,
      //     filter: "text",
      //     noOverflow: true,
      // },
      // {
      //     title: "订购日期",
      //     show: true,
      //     name: "order_dt",
      //     type: "date",
      //     width: 140,
      //     filter: "dateRange",
      //     noOverflow: true,
      // },
      // {
      //     title: "销售日期",
      //     show: true,
      //     name: "sale_dt",
      //     type: "date",
      //     width: 140,
      //     filter: "dateRange",
      //     noOverflow: true,
      // },
      {
        title: "营业日期",
        show: true,
        name: "cl_operation_dt",
        type: "date",
        width: 140,
        filter: "dateRange",
        noOverflow: true
      }, {
        title: "交易类型",
        show: true,
        name: "cl_doc_type_name",
        type: "text",
        width: 180,
        filter: "text",
        noOverflow: true
      }, {
        title: "数量",
        show: true,
        name: "cl_quantity",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "物流成本",
        show: true,
        name: "cl_delivery_rub",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "SPP, %",
        show: true,
        name: "f_cl_spp",
        type: "percent",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "基本佣金, %",
        show: true,
        name: "f_cl_prc_base",
        type: "percent",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "实际佣金, %",
        show: true,
        name: "f_cl_prc_fact",
        type: "percent",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      },
      // {
      //     title: "佣金, ¥",
      //     show: true,
      //     name: "cl_sales_commission",
      //     type: "money",
      //     width: 220,
      //     filter: "numberRange",
      //     noOverflow: true,
      // },
      {
        title: "实际佣金, RUB",
        show: true,
        name: "cl_sales_commission_total",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "货物转运 RUB",
        show: true,
        name: "cl_for_pay",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }
      // {
      //     title: "添加日期",
      //     show: true,
      //     name: "date_add",
      //     type: "date",
      //     width: 160,
      //     filter: "dateRange",
      //     noOverflow: true,
      // },
      ]

      // OZON
      : [{
        title: "№",
        show: true,
        name: "row_number",
        type: "text",
        width: 60,
        filter: "text",
        noOverflow: true
      }, {
        title: "OZON ID",
        show: true,
        name: "product_id",
        type: "text",
        width: 100,
        noOverflow: true
      }, {
        title: "产品",
        show: true,
        name: "name",
        type: "text",
        width: 500,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "产品识别代码",
        show: true,
        name: "offer_id",
        type: "text",
        width: 160,
        filter: "text",
        noOverflow: true
      },
      //Блок продаж
      {
        title: "% 佣金",
        show: true,
        name: "commission_percent",
        type: "percent",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "价格包含折扣",
        show: true,
        name: "price",
        type: "money",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "售价",
        show: true,
        name: "price_sale",
        type: "money",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "数量",
        show: true,
        name: "sale_qty",
        type: "number",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "已按金额出售",
        show: true,
        name: "sale_amount",
        type: "money",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "佣金",
        show: true,
        name: "sale_commission",
        type: "money",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "以 Ozon 为代价的补偿",
        show: true,
        name: "sale_discount",
        type: "money",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "应计总额",
        show: true,
        name: "sale_price_seller",
        type: "money",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      },
      // Блок возвратов
      {
        title: "退货价格",
        show: true,
        name: "return_sale",
        type: "money",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "退货数量",
        show: true,
        name: "return_qty",
        type: "number",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "已退还相应金额",
        show: true,
        name: "return_amount",
        type: "money",
        width: 120,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "佣金返还",
        show: true,
        name: "return_commission",
        type: "money",
        width: 120,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "退款",
        show: true,
        name: "return_discount",
        type: "money",
        width: 120,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "返回总数",
        show: true,
        name: "return_price_seller",
        type: "money",
        width: 120,
        filter: "numberRange",
        noOverflow: true
      }];
    },
    formatDate1() {
      let split = this.reportSettings.date.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    },
    formatDate2() {
      let split = this.reportSettings.date2.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    }
  }
};