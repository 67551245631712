//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    /*
    async loadProducts(items) {
        const products = [];
        for (let i = 0; i < items.length; i++) {
            products.push(
                this.$store.dispatch("product/getProduct", {
                    p_id: items[i].nm_id,
                    mp: this.reportSettings.datasource,
                    date: this.formatDate1,
                    date2: this.formatDate2,
                    fbs: 0,
                })
            );
        }
        for (let i = 0; i < products.length; i++) {
            try {
                const data = await products[i];
                items[i].product = data.title;
                items[i].image = data.image;
            } catch (e) {
                console.log(e);
            }
        }
        return items;
    },
    */
    async tableLoader({
      query
    }) {
      const data = await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbFinancialAnalysisByExpenses", "connections/getOzFinancialAnalysisByExpenses", {}, {});
      if (data.type !== "excel") {
        //data.items = await this.loadProducts(data.items);
      } else {
        const filter = data.datas[0].sheetFilter;
        const header = data.datas[0].sheetHeader;
        data.datas[0].sheetFilter = filter.filter(item => item !== "product");
        data.datas[0].sheetHeader = header.filter(item => item !== "产品");
      }
      return data;
    }
  },
  computed: {
    columns() {
      return this.$store.state.connections.marketplace.title === "Wildberries" ? []
      // OZON
      : [{
        title: "类别列表排名",
        show: true,
        name: "name",
        type: "text",
        width: 300,
        filter: "text",
        noOverflow: true
      }, {
        title: "id",
        show: true,
        name: "id",
        type: "text",
        width: 300,
        filter: "text",
        noOverflow: true
      }, {
        title: "数量",
        show: true,
        name: "value",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }];
    },
    formatDate1() {
      let split = this.reportSettings.date.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    },
    formatDate2() {
      let split = this.reportSettings.date2.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    }
  }
};